'use strict';


//--Please note, use lowercase for the name as the calls will be using lowercase.
export const modalContents = [
    {
        name: 'terms-directdebit',
        contentType: 'url',
        content: 'policies/direct-debit-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'rac-ng-terms',
        contentType: 'url',
        content: '/rac/ng-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'rac50-ng-terms',
        contentType: 'url',
        content: '/rac/ng50-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'racbonus50-ng-terms',
        contentType: 'url',
        content: '/rac/natural-gas/racbonus50ng-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'racbonus50-ng-plans-terms',
        contentType: 'url',
        content: '/rac/natural-gas/racbonus50ng-plans-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'rac38and50-ng-terms',
        contentType: 'url',
        content: '/rac/natural-gas/rac38and50ng-terms-conditions',
        selector: '[id=main] div[class=content]'
    },  
    {
        name: 'rac38and50-ng-plans-terms',
        contentType: 'url',
        content: '/rac/natural-gas/rac38and50ng-plans-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'rac-offer-end-terms',
        contentType: 'url',
        content: '/rac/offer-end-terms',
        selector: '[id=main] div[class=content]'
    },  
    {
        name: 'value-rac-kleenheat',
        contentType: 'url',
        content: '/help/rewards-offers/value-rac-kleenheat',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'rac-lpg-terms',
        contentType: 'url',
        content: '/rac/lpg-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys-terms',
        contentType: 'url',
        content: 'natural-gas/rewards/flybuys-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'terms-tcc-two-tickets',
        contentType: 'url',
        content: '/about/community/telethon-community-cinemas/terms',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'waseniors-terms',
        contentType: 'url',
        content: 'natural-gas/rewards/waseniors-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'secure_payment',
        contentType: 'html',
        content:
            '<p>Your payment information is confidentially transmitted to a secure payment processing service, to provide additional protection to your sensitive information.</p>',
        selector: ''
    },
    {
        name: 'no_rac_number',
        contentType: 'html',
        content:
            '<p>Don\'t have RAC Number</p>',
        selector: ''
    },
    {
        name: 'monthly_instalments',
        contentType: 'iframe',
        content:
        '//www.youtube.com/embed/gda5tA5p-Yk?wmode=opaque&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=2&amp;autohide=1&amp;enablejsapi=1',
            selector: ''
    },
    {
        name: 'why_dob',
        content: 'We\'ll use your date of birth to verify your identity when you contact customer service and to check your eligibility for certain products or offers. We also compile data on the age of our customers to better understand how to meet customer needs.'
    },
    {
        name: 'standard_prices_and_fees',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/natural-gas-standard-prices-fees',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys_plan_terms_conditions',
        contentType: 'url',
        content: '/flybuys/plan-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys_dollar_spend_terms_conditions',
        contentType: 'url',
        content: '/flybuys/dollar-spend-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys_10k_terms_conditions',
        contentType: 'url',
        content: '/flybuys/10k-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_1',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/standard-form-contract',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_2',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/monthly-smart-saver-plan-details',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_2_summer',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/monthly-smart-saver-plan-details?promo-discount=35&promo-duration=12',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_537',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/monthly-energiser-plan-details',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_rac_1',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/standard-form-contract?promo-discount=15.5',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_rac_2',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/monthly-smart-saver-plan-details?promo-discount=28',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_rac_2_summer',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/monthly-smart-saver-plan-details?promo-discount=38&promo-duration=12',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'plan_details_and_terms_rac_537',
        contentType: 'url',
        content: '/natural-gas/plans-and-pricing/monthly-energiser-plan-details?promo-discount=38',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys',
        contentType: 'html',
        content:
            '<ul><li> Log in to My Account</li><li>Under the "My Details" tab, click "Rewards"</li> <li>Enter your flybuys membership number and click "Save details"</li></ul><p>That\'s it! Your bonus will be reflected in your next Kleenheat invoice.</p><p>For the moment, you can sign up without adding your flybuys number.</p>',
        selector: ''
    },
    {
        name: 'rac',
        contentType: 'html',
        content:
            '<ul><li> Log in to My Account</li><li>Under the "My Details" tab, click "Rewards"</li> <li>Enter your RAC membership number and click "Save details"</li></ul> <p>That\'s it! Any further discounts you qualify for will be reflected in your next Kleenheat invoice.</p>',
        selector: ''
    },
    {
        name: 'switchplans',
        contentType: 'html',
        content: '<p>Looks like you\'re looking to apply our _displayText_ bonus on _currentPlan_. We think you might get better long term value by applying this offer against our _newPlan_ plan.</p> <p>Would you like to apply this bonus to the _newPlan_ plan instead?</p>',
        selector: ''
    },
    {
        name: 'promopartner',
        contentType: 'html',
        content: '<p>Are you a _membership_ member?</p>',
        selector: ''
    },
    {
        name: 'what_monthly_instalments',
        contentType: 'url',
        content: 'natural-gas/plans-and-pricing/monthly-instalments/what-are-monthly-instalments',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'non_standard_terms',
        contentType: 'url',
        content: 'natural-gas/plans-and-pricing/',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'gas_service_terms',
        contentType: 'url',
        content: 'gas-service-data-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'privacy_policy',
        contentType: 'url',
        content: 'policies/privacy-policy',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'collection_of_information_terms',
        contentType: 'url',
        content: 'policies/collection-of-information-statement',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'hint_find_address',
        contentType: 'url',
        content: 'help/signing-up/cant-find-address',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'lpg_acquisition_offer',
        contentType: 'url',
        content: '/lpg/new-customer/lpg-save30-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'lpg_order_terms',
        contentType: 'url',
        content: '/policies/online-ordering-discount-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'employee_discount_offer_terms',
        contentType: 'html',
        content: '<p>40% off the usage component of the standard tariff for 12 months when selecting the Monthly Energiser Plan (new and existing customers) or the Monthly Smart Saver plan (existing customers only). Offer is only available to WA residential customers in areas where Kleenheat supplies natural gas. Your application must be accepted by Kleenheat. This offer may be extended, cancelled or changed at any time at the discretion of Kleenheat.</p>',
        selector: ''
    },
    {
        name: 'family_friends_offer_terms',
        contentType: 'html',
        content: '<p>40% off the usage component of the standard tariff for 12 months when selecting the Monthly Energiser Plan (new and existing customers) or the Monthly Smart Saver plan (existing customers only). Offer is only available to WA residential customers in areas where Kleenheat supplies natural gas. Your application must be accepted by Kleenheat. This offer may be extended, cancelled or changed at any time at the discretion of Kleenheat. This offer cannot be used in conjunction with any other promotional offer. Other terms, conditions, fees and charges apply.</p>',
        selector: ''
    },
    {
        name: 'comp_dyson_terms',
        contentType: 'url',
        content: '/terms-conditions/dyson-competition',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'fringe_world_voucher_giveaway_terms',
        contentType: 'url',
        content: '/terms-conditions/fringe-voucher-giveaway',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'terms-carbonoffset-opt-in',
        contentType: 'url',
        content: '/about/carbon-offset/terms',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys20x_terms_conditions',
        contentType: 'url',
        content: '/flybuys-20x-offer/flybuys-20x-offer-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys10x_terms_conditions',
        contentType: 'url',
        content: '/flybuys-10x-offer/flybuys-10x-offer-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys-win-terms',
        contentType: 'url',
        content: '/flybuys-win/flybuys-win-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys-win-1m-terms',
        contentType: 'url',
        content: '/flybuys-win-1m/flybuys-giveaway-terms-and-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'business_case_study_varsity',
        contentType: 'video',
        content:
        '<iframe class="mer-dialog__video" src="//player.vimeo.com/video/714320164?color=009ee3&amp;title=0&amp;byline=0&amp;portrait=0" allowFullScreen="true" frameBorder="0" width="100%" height="100%"></iframe>'
    },
    {
        name: 'business_case_study_windward',
        contentType: 'video',
        content:
        '<iframe class="mer-dialog__video" src="//player.vimeo.com/video/725523183?color=009ee3&amp;title=0&amp;byline=0&amp;portrait=0" allowFullScreen="true" frameBorder="0" width="100%" height="100%"></iframe>'
    },
    {
        name: 'flybuys-2x-terms',
        contentType: 'url',
        content: '/flybuys-2x-offer-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'flybuys-5x-terms',
        contentType: 'url',
        content: '/flybuys-5x-offer-terms-conditions',
        selector: '[id=main] div[class=content]'
    },
    {
        name: 'summer-terms',
        contentType: 'url',
        content: '/natural-gas/rewards/summer-terms-and-conditions',
        selector: '[id=main] div[class=content]'
    }
];